import React, { CSSProperties } from "react";
import { Box, Typography, styled } from "@mui/material";
import {
  formatCurrency,
  formatCurrencySRP,
  productDetailsPageUrl,
} from "common";
import BazarCard from "components/BazarCard";
import LazyImage from "components/LazyImage";
import { H3 } from "components/Typography";
import { Product } from "modules/products/types";
import Link from "next/link";
import { FlexBox } from "../flex-box";
import { useIsMobile } from "hooks";

const StyledBazarCard = styled(BazarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: { display: "block" },
}));

const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
  },
}));

// ========================================================
type ProductCard1Props = {
  className?: string;
  style?: CSSProperties;
  hoverEffect?: boolean;
  slug?: string;
  category?: string;
  category_id?: number;
  product_name?: string;
  img_thumbnail?: string;
  mfr_logo?: string;
  isMobile?: boolean;
  amount?: number;
  productData: Product;
};
// ========================================================

// TODO: Just create a common prop maybe 'Product'
const NewProductCard: React.FC<ProductCard1Props> = ({
  hoverEffect,
  productData,
}) => {
  const isMobile = useIsMobile();

  return (
    <StyledBazarCard hoverEffect={hoverEffect}>
      <FlexBox
        sx={{
          flexDirection: "row",
          p: 1,
          px: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LazyImage
          src={productData?.mfr_logo}
          width={isMobile ? 75 : 50}
          height={30}
          layout="fixed"
          objectFit="contain"
        />
        <FlexBox
          sx={{
            borderRadius: "38px",
            width: "auto",
            py: 1,
            px: 2,
            background: "#2B2EA2",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color={"white"}
            fontSize={"12px"}
            textAlign={"center"}
            fontWeight={600}
          >
            {`SAVE ${formatCurrency(productData?.discounted_amount || 0)}`}
          </Typography>
        </FlexBox>
      </FlexBox>
      <ImageWrapper>
        <Link
          href={productDetailsPageUrl(
            productData?.slug,
            productData?.product_uuid
          )}
        >
          <a>
            <Box
              sx={{
                alignItems: "center",
                paddingX: "16px",
              }}
            >
              <LazyImage
                src={
                  productData?.img_thumbnail ||
                  "/assets/images/sad_red_hair.png"
                }
                width={0}
                height={0}
                layout="responsive"
                objectFit="contain"
                alt={productData?.product_name}
              />
            </Box>
          </a>
        </Link>
      </ImageWrapper>
      <ContentWrapper>
        <Link
          href={productDetailsPageUrl(
            productData?.slug,
            productData?.product_uuid
          )}
        >
          <a>
            <FlexBox>
              <Box flex="1 1 0" minWidth="0px" mr={1}>
                <H3
                  mb={1}
                  title={productData?.product_name}
                  fontSize="14px"
                  fontWeight="700"
                  className="title"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  {productData?.product_name}
                </H3>
                <FlexBox
                  flexDirection="row"
                  mt={0.5}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Box fontSize="20px" fontWeight="600" color="primary.main">
                    {formatCurrency(productData?.amount)}
                  </Box>
                  <Box
                    ml={1}
                    fontSize="14px"
                    color="grey.600"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {formatCurrencySRP(productData?.amount)}
                  </Box>
                </FlexBox>
              </Box>
            </FlexBox>
          </a>
        </Link>
      </ContentWrapper>
    </StyledBazarCard>
  );
};
export default NewProductCard;
